.add_tag_container {
  display: flex;
  justify-content: space-between;
  max-height: 100%;
  overflow: scroll;
}

.autocomplete_container {
  width: 55%;
}
