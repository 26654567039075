.root {
  max-width: 100% !important;
}

.filter_group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter_group_item {
  width: 30%;
}

.kpi_settings_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.kpi_item {
  display: flex;
  width: 20%;
  /* width: 30%; */
  height: 10vh;
  /* padding-bottom: 5%; */
  /* align-content: center; */
  align-items: center;
  text-align: center;
  padding-left: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  cursor: pointer;

  /* max-width: 30% !important; */
  /* margin-right: 3.2%; */
}

.kpi_item:hover {
  background-color: var(--color-bg-sidebar-item-hover) !important;
}

.kpi_value {
  max-width: 90px;
  word-wrap: break-word;
}

.add_kpi_text {
  margin: auto;
  color: rgb(171, 171, 171);
}

.chart_settings_row {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.kpi_clear_icon {
  /* max-width: 2%; */

  margin-top: 35px;
  margin-left: 2px;
  /* margin-right: 5px; */
  cursor: pointer;
}

.chart_setting {
  width: 27%;
}

.chart_currency_filter {
  width: 13%;
}
.chart_clear_icon {
  width: 5%;
  cursor: pointer;
}

.add_chart_button {
  border: solid 0.5px black !important;
}

.loading {
  margin: auto 50px;
}
