.details_line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detail {
  width: 43%;
}

.arrow {
  width: 3%;
}
.gender_select {
  width: 28%;
  margin-right: 15%;
}

.not_set {
  color: rgb(171, 164, 164);
  font-style: italic;
}

.text_input {
  margin: 0 !important;
}
